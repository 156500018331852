<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>taxi-wave-businessman</title>
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="12.75" cy="3.375" r="2.625" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15,18.75V18a2.25,2.25,0,0,1,4.5,0v.75"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12.75,23.25V19.5a.75.75,0,0,1,.75-.75H21a.75.75,0,0,1,.75.75v3.75"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9.75,23.25l0-12-7.118-8A1.5,1.5,0,1,1,4.87,1.256L9.528,6.493A3,3,0,0,0,11.77,7.5h2.48a4.5,4.5,0,0,1,4.5,4.5v2.25a1.5,1.5,0,0,1-3,0V12"
    />
  </svg>
</template>
